// Color Variables
// Bootstrap Color Defaults
$white: #fff;
$gray-100: #f8f8f8;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6a6a6a;
$gray-700: #495057;
$gray-800: #303030;
$gray-900: #212529;
$black: #000;
$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #f48f03;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;
$primary: #F39100;
$body-color: $gray-800;
$theme-colors: ( "primary": $primary, "danger": #ff4136);
$font-size-base: .8rem;
$h1-font-size: $font-size-base * 1.25;
$h2-font-size: $font-size-base * 1.1;
$h3-font-size: $font-size-base * 1.1;
$h4-font-size: $font-size-base * 1;
$h5-font-size: $font-size-base * 1;
$h6-font-size: $font-size-base;
$form-group-margin-bottom: .3rem;

//sidebar portal
$sidebar-background-color: $primary;
$sidebar-menu-hover-background-color: darken($sidebar-background-color, 8%);
$sidebar-bottom-border: 1px solid darken($sidebar-background-color, 10%);
$sidebar-menu-active-background-color: $sidebar-menu-hover-background-color;
$sidebar-width-open-desktop: 275px;
$sidebar-width-closed-desktop: 75px;

$portal-general-padding: 15px;
$portal-help-background-color: #f5f5f5;
$portal-help-color: #575757;
$portal-status-neutral: #818181;
$portal-status-in-progress: #0068b4;
$portal-status-ready: #00963e;
$portal-status-error: #e3000b;
$portal-status-warning: $primary;
$portal-table-bg-cell-color: #f5f5f5;
$portal-table-bg-cell-color-selected: $sidebar-background-color;

//traject
$traject-highlight-color: $gray-600;
$traject-gap: 2px;
$traject-status-neutral: $portal-status-neutral;
$traject-status-width: 7px;
$traject-item-height: 85px;
$traject-item-background-color: lighten($portal-status-neutral, 45%);
$traject-item-active-background-color: lighten($primary, 20%);
$traject-action-width: 40%;
$traject-info-width: 60%;
$traject-transition: all .2s ease-in-out;

//pricegroups
$pricegroup-gap: $traject-gap;
$pricegroup_labelwidth: 130px;
$pricegroup_border_style: 1px solid $gray-300;
$pricegroup-rows-bg: $portal-table-bg-cell-color;
$pricegroup-active: $primary;

//transport
$transport-status-loaded: $red;
$transport-status-unloaded: $green;
$transport-status-douane-imported: $black;
$transport-status-in-transit: $yellow;

// Yes/no toggler
$toggler-active-background-color: $primary;
$toggler-width: 60px;
$toggler-height: 24px;
