//settings
$nav-item-show-icon: true;

//main menu
$nav-main-bg-color: $gray-100;

//menu item
$nav-item-bg-color: $gray-200;
$nav-item-color: rgba(0, 0, 0, 0.5);
$nav-item-font-size: .85rem;
$nav-item-font-weight: normal;
$nav-item-margin-bottom: 1px;
$nav-item-border-style: none;
$nav-item-padding: 0.3em 1em;
$nav-item-active-color: $orange;
$nav-item-active-background-color: $nav-item-bg-color;
$nav-item-hover-color: white;
$nav-item-hover-background-color: $orange;
$sidenav-margin-top: 50px;
$navlink-top-background: $nav-item-bg-color;

//submenu
$nav-subitem-color: $nav-item-color;

//navbar
$navbar-base-height: 37px;
$navbar-border-bottom-width: 0;
$navbar-logo-width: 133px;
$navbar-logo-height: auto;
$navbar-border-bottom: none;
$navbar-padding-y: 0;
$navbar-padding-x: 0;
$navbar-text-align: left;

$input-bg: #f5f5f5;
$input-disabled-bg: darken($input-bg, 10%);
$input-group-addon-bg: $input-bg;
$input-border-width: 1px;
