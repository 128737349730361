html,
body {
  height: 100%;
}

a:hover {
  text-decoration: underline;
}

.input-group {
  -webkit-box-shadow: 8px 7px 19px -4px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 8px 7px 19px -4px rgba(0, 0, 0, 0.15);
  box-shadow: 8px 7px 19px -4px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: $input-bg;
  border-width: $input-border-width;
  -webkit-text-fill-color: $body-color;
  -webkit-box-shadow: 0 0 0 1000px $input-bg inset;
  transition: background-color 1000s ease-in-out 0s;
}

#soncrota_logo {
  @extend .img-fluid;
  @extend .mb-3;
  width: 100%;
  max-width: 250px;
  margin-left: -13px;
}

#login_title {
  color: $gray-700;
  font-size: 1.3rem;
}

#certs {
  max-width: 250px;
}

#remember_me {
  @extend .float-left;
  margin-left: 10px;

  input[type=checkbox] {
    margin-top: .25rem;
  }
}

#forgot_password {
  @extend .float-right;
}

#submit_btn,
#reset_submit_btn {

  @extend .mt-3;
  @extend .mb-3;
  padding: calc($spacer/4) calc($spacer*2);
  font-weight: normal;
  width: 100%;

  @media all and (min-width: map-get($grid-breakpoints, "sm")) {
    float: right;
    width: auto;
  }
}

#page {
  @extend .container-fluid;
  @extend .h-100;

  & > .wrapper {
    @extend .row;
    @extend .h-100;
    @extend .align-items-start;
  }
}

#banner {
  @extend .col-sm-4;
  @extend .text-center;
  @extend .mh-100;

  background-image: url("/assets/themes/soncotra/banner_bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  & > .wrapper {
    @extend .mh-100;
  }
}

#slogan {
  text-align: right;
  margin: 1rem 0;
  color: $white;
  font-size: .9rem;
  font-weight: 100;

  h2 {
    font-size: 1.4rem;
    font-weight: 100;
  }

  @media all and (min-width: map-get($grid-breakpoints, "sm")) {
    min-height: 50vh;
    margin: 0;

    h2 {
      padding-top: 10vh;
      font-size: 1.8rem;
      font-weight: 100;
      margin: 0;
    }
  }

  @media all and (min-width: map-get($grid-breakpoints, "md")) {
    h2 {
      font-size: 2.2rem;
    }
  }
}

#address-info {
  text-align: left;
  color: $white;
  font-size: .7rem;

  strong {
    font-size: .8rem;
  }

  @media all and (min-width: map-get($grid-breakpoints, "sm")) {
    min-height: 50vh;
    position: relative;

    font-size: .9em;

    strong {
      font-size: 1rem;
    }


    p:last-of-type {
      margin-bottom: 0;
    }

    .wrapper {
      position: absolute;
      bottom: 1rem;
      left: 0;
    }


  }

}

#lang_menu {
  margin-bottom: 2rem;
}

#login_area {
  @extend .col-sm-8;
  @extend .mh-100;
}

#topbar {
  @extend .row;

  & > .wrapper {
    @extend .col-12;
    @extend .text-right;
    @extend .mt-4;
  }

  @media all and (min-width: map-get($grid-breakpoints, "sm")) {
    min-height: 30vh;
  }
}

#login {
  @extend .row;
  @extend .align-items-center;

  @media all and (min-width: map-get($grid-breakpoints, "sm")) {
    min-height: 60vh;
  }
}

#login_content {
  @extend .col-10;
  @extend .offset-1;
  @extend .col-md-8;
  @extend .offset-md-2;
  @extend .col-lg-6;
  @extend .offset-lg-3;

  @extend .justify-content-center;
  @extend .h-100;

  & > .wrapper {
    @extend .row;

    & > div {
      @extend .col-12;
    }
  }

  .input-group {
    @extend .mb-1;
    @extend .mt-2;
  }
}

#login_footer {
  @extend .row;

  @media all and (min-width: map-get($grid-breakpoints, "sm")) {
    min-height: 10vh;
  }
}

#login_footer_content {
  @extend .col-10;
  @extend .offset-1;
  @extend .offset-sm-1;
  @extend .col-md-8;
  @extend .offset-md-2;
  @extend .col-lg-6;
  @extend .offset-lg-3;
  @extend .justify-content-center;
  @extend .h-100;
  margin-top: 2rem;
  margin-bottom: 2rem;

  img {
    @extend .img-fluid;
    width: 100%;
    max-width: 200px;
  }

  @media all and (min-width: map-get($grid-breakpoints, "sm")) {
    margin-top: 0;
    margin-bottom: 0;
  }
}








