a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

h3 {
  color: $primary;
  font-size: .8rem;
}

.btn-primary {
  color: #FFF;
}


.btn-outline-primary:hover {
  color: #FFF;
}

.collapse-arrow-icons {
  &::after {
    transition: transform .2s ease-out;
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }

  &.collapsed::after {
    transform: rotate(-90deg);
  }
}

.modal {
  .item {
    @extend .mt-2;
    @extend .pt-2;
    border-top: solid 1px $portal-help-background-color;
  }
}

.already_uploaded {
  opacity: .3;

  &:hover {
    opacity: .9;
  }
}

// YES/NO toggler
.switch {
  vertical-align: middle;
  position: relative;
  display: inline-block;
  width: $toggler-width !important;
  height: $toggler-height;

  &.disabled {
    opacity: .6 !important;
    pointer-events: none;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: $toggler-height / 4;

    &::before {
      position: absolute;
      content: "";
      height: ($toggler-height - 4px);
      width: ($toggler-height - 4px);
      left: 2px;
      bottom: 2px;
      background-color: $white;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 30%;
    }
  }

  input:checked + .slider {
    background-color: $toggler-active-background-color;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $toggler-active-background-color;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(35px);
    -ms-transform: translateX(35px);
    transform: translateX(35px);
  }

  input + .slider + .label:before {
    display: block;
    position: absolute;
    left: 4px;
    top: 2px;
    width: $toggler-width - 8px;
    height: $toggler-height;
    line-height: $toggler-height - 4px;
    content: "off";
    font-weight: normal;
    color: $white;
    text-align: right;
    text-transform: uppercase;
  }

  input:checked + .slider + .label:before {
    content: "on";
    text-align: left;
  }


  &.min-max {
    input + .slider + .label:before {
      content: "min";
    }

    input:checked + .slider + .label:before {
      content: "max";
    }
  }

  &.yes-no {
    input + .slider + .label:before {
      content: "no";
    }

    input:checked + .slider + .label:before {
      content: "yes";
    }
  }

  &.pricetype {

    .slider {
      background-color: $blue;
    }

    input + .slider + .label:before {
      content: "EUR";
    }

    input:checked + .slider + .label:before {
      content: "%";
    }
  }

  &.driver-truck {

    input:checked + .slider:before {
      -webkit-transform: translateX(45px);
      -ms-transform: translateX(45px);
      transform: translateX(45px);
    }

    .slider {
      width: 70px;
    }

    input + .slider + .label:before {
      content: "Driver";
      width: 60px;
    }

    input:checked + .slider + .label:before {
      content: "Truck";
    }
  }
}

.switch-label {
  line-height: 1.5rem;
}

.simpleform {

  & > div,
  & > form {
    @extend .col-12;
  }

  form {
    font-size: .7rem;

    & > div {
      @extend .form-row;

      &:last-of-type {
        @extend .mt-3;
      }


      & > div {
        @extend .form-group;
        @extend .col-md-4;
        margin-bottom: .3rem;

        label {
          font-size: .65rem;
          font-weight: 600;
          margin-bottom: 0;
        }

        input[type=text],
        input[type=password],
        input[type=date],
        input[type=number],
        select {
          @extend .form-control;
          @extend .form-control-sm;
        }
      }
    }
  }

  button[type=submit] {
    @extend .btn;
    @extend .btn-sm;
    @extend .btn-primary;
    @extend .mt-3;
    @extend .mt-md-0;
  }
}

.collapseable {
  background-color: $portal-help-background-color;
  padding-top: $portal-general-padding;
  padding-bottom: $portal-general-padding;

  & > div {
    @extend .col-12;
  }

  .title {
    position: relative;

    a {
      @extend .dropdown-toggle;
      color: $portal-help-color;
      font-weight: 500;
      font-size: .9rem;
      display: block;

      &::after {
        transform: translateY(-50%);
        display: block;
        position: absolute;
        top: 10px;
        right: 10px;
        color: #afaeae;
      }

      &.collapsed::after {
        transform: rotate(90deg);
      }

      &:hover {
        padding-left: calc($portal-general-padding / 2);

        &::after {
          right: calc(10px + ($portal-general-padding / 2));
          color: $portal-help-color;
        }
      }
    }
  }

  .details {
    @extend .mt-3;

    color: $portal-help-color;
  }

  &:hover {
    background-color: darken($portal-help-background-color, 10%);
  }

  &:nth-child(even) {
    background-color: darken($portal-help-background-color, 5%);
  }
}

.sonc-table {
  @extend .mt-3;
  @extend .table;
  @extend .table-sm;

  font-size: .65rem;
  min-width: 1200px;

  thead {
    tr {
      border: none;

      th, td {
        border: none;
        background-color: #898989;
        color: #fff;
        font-weight: 100;
      }
    }
  }


  tbody {
    tr {
      td {
        padding: .2rem .5rem;
        border: 2px solid #fff;
        background-color: $portal-table-bg-cell-color;

        a {
          text-decoration: underline;
        }

        &:last-child {
          border-right: none;
        }

        &:first-child > div {
          box-sizing: border-box;
          height: 100%;

        }
      }

      &:nth-child(odd) td {
        background-color: darken($portal-table-bg-cell-color, 5%);
      }

      &:hover {
        td {
          background-color: darken($portal-table-bg-cell-color, 15%);
        }

        td:first-child > div {
          border-left-color: lighten($portal-status-neutral, 20%);
        }
      }

      &.selected {
        td {
          background-color: $portal-table-bg-cell-color-selected !important;
          color: #fff;

          div.documents {
            a {
              filter: brightness(0) invert(1);
            }
          }

          div.comments {
            i {
              color: darken($white, 8%);
            }

            span {
              background-color: darken($white, 8%);
              color: $primary;
            }

            button:hover {
              i {
                color: $white;
              }

              span {
                background-color: $white;
              }
            }
          }
        }

        &:hover td {
          background-color: lighten($portal-table-bg-cell-color-selected, 10%) !important;
        }
      }
    }
  }

  > tbody > tr > td:first-child {
    border-left: 0;
    padding-left: 5px;
    -moz-box-shadow: inset 5px 0 $portal-status-neutral;
    -webkit-box-shadow: inset 5px 0 $portal-status-neutral;
    box-shadow: inset 5px 0 $portal-status-neutral;
  }

  tr > td table > tbody {
    tr, td {
      padding: 0;
      margin: 0;
      border: 0;
      background-color: unset !important;

      &:first-child {
        padding-left: 0;
      }
    }

    tr {
      & + tr {
        border-top: 1px solid $gray-500;

        td {
          padding-top: 3px;
        }
      }

      td {
        padding-bottom: 3px;
      }
    }
  }
}

// todo better to split up, and get rid of extend
.portal-table {
  @extend .sonc-table;

  thead {
    &.error {
      tr {
        th, td {
          i {
            color: $portal-status-error;
          }
        }
      }
    }
  }

  tbody {

    tr {
      > td:first-child {
        padding-left: 5px;
      }

      td {
        margin: 0;
        padding: 0px;

        & > div {
          display: block;
          width: 100%;
          height: 100%;
          padding: .6rem .3rem;

          &.comments {
            text-align: center;
            padding: .35rem;

            button {
              @extend .btn;
              padding: 0;

              i {
                font-size: $font-size-base*1.5;
                color: lighten($gray-600, 20%);
              }

              span {
                position: absolute;
                background-color: lighten($gray-600, 20%);
                color: white;
                border-radius: 50%;
                font-size: $font-size-base - .3rem;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 14px;
                height: 14px;
                margin-top: -28px;
                margin-left: 10px;
                padding-bottom: 1px;
              }

              &:hover {
                i {
                  color: $gray-600;
                }

                span {
                  background-color: $gray-600;
                }
              }
            }
          }
        }
      }

      &.status-in-progress > td:first-child {
        //border-left-color: $portal-status-in-progress;
        -moz-box-shadow: inset 5px 0 $portal-status-in-progress;
        -webkit-box-shadow: inset 5px 0 $portal-status-in-progress;
        box-shadow: inset 5px 0 $portal-status-in-progress;
      }

      &.status-ready > td:first-child {
        //border-left-color: $portal-status-ready;
        -moz-box-shadow: inset 5px 0 $portal-status-ready;
        -webkit-box-shadow: inset 5px 0 $portal-status-ready;
        box-shadow: inset 5px 0 $portal-status-ready;
      }

      &.status-error > td:first-child {
        //border-left-color: $portal-status-error;
        -moz-box-shadow: inset 5px 0 $portal-status-error;
        -webkit-box-shadow: inset 5px 0 $portal-status-error;
        box-shadow: inset 5px 0 $portal-status-error;
      }

      &.status-warning > td:first-child {
        //border-left-color: $portal-status-warning;
        -moz-box-shadow: inset 5px 0 $portal-status-warning;
        -webkit-box-shadow: inset 5px 0 $portal-status-warning;
        box-shadow: inset 5px 0 $portal-status-warning;
      }
    }

    &.error {
      > tr {
        > td:first-child {
          //border-left-color: $portal-status-error;
          -moz-box-shadow: inset 5px 0 $portal-status-error;
          -webkit-box-shadow: inset 5px 0 $portal-status-error;
          box-shadow: inset 5px 0 $portal-status-error;

        }

        > &.status-ready {
          > td:first-child {
            //border-left-color: $portal-status-ready;
            -moz-box-shadow: inset 5px 0 $portal-status-ready;
            -webkit-box-shadow: inset 5px 0 $portal-status-ready;
            box-shadow: inset 5px 0 $portal-status-ready;
          }
        }
      }
    }
  }

  a.delete-btn {
    @extend .btn;
    @extend .btn-sm;
    @extend .btn-outline-secondary;

    margin: .2rem;
    cursor: pointer;
  }
}

.table-spaced-sm td > div {
  padding: .2rem !important;
}

div.documents {
  padding: .35rem;
  display: flex;
  flex-wrap: nowrap;

  a {
    opacity: .6;
    display: inline-block;
    width: 32px;
    height: 22px;
    margin-bottom: 2px;
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    vertical-align: middle;
    background-color: transparent;

    &:hover {
      opacity: 1;
    }

    &.xml {
      background-image: url("/../images/icons/xml.png");
    }

  }

  &.status-color a {
    background-color: transparent;

    &.rejected {
      -webkit-filter: invert(40%) grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(400%) contrast(2);
      filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(0.8);
    }

    &.approved {
      -webkit-filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(50deg) saturate(1000%) contrast(0.8);
      filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(50deg) saturate(1000%) contrast(0.8);
    }
  }

  &.checklist {
    > div {
      display: flex;
      align-items: center;
      @extend .mb-2;
    }

    a {
      height: 14px;
    }

    &::after {
      content: none;
    }
  }
}

.action_header {
  @extend .pt-3;
  @extend .row;

  h1 {
    @extend .col-12;
    @extend .col-md-8;
  }

  h1 + div {
    @extend .col-12;
    @extend .col-md-4;
    @extend .mt-4;
    @extend .mt-md-0;

    @media all and (min-width: map-get($grid-breakpoints, "md")) {
      text-align: right;
    }

    span, a {
      @extend .btn;
      @extend .btn-sm;
      @extend .btn-primary;
      color: $white !important;
    }
  }
}

.global-modal {
  .modal-body {
    @extend .container-fluid;

    & > div {
      @extend .row;

      & > div {
        @extend .form-group;
        @extend .col;

        input, select {
          @extend .form-control;
        }
      }
    }
  }

  .modal-body, .modal-footer {
    button, input[type=submit] {
      @extend .btn;
      @extend .btn-primary;
    }
  }
}

.simple-list {
  padding: 0;
  list-style: none;
}

.bootstrap-select {
  display: flex;
  min-width: 100%;
  max-width: 100%;
}

.table-responsive {
  overflow-y: hidden;
}

.row.no-gutters {
  margin-right: 0;
  margin-left: 0;

  & > [class^="col-"],
  & > [class*=" col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.btn-outline-progress {
  color: $portal-status-in-progress;
  border-color: $portal-status-in-progress;

  &:hover {
    color: $white;
    background-color: $portal-status-in-progress;
    border-color: $portal-status-in-progress;
  }
}

.btn-progress {
  color: $white;
  background-color: $portal-status-in-progress;;
  border-color: $portal-status-in-progress;;
}


#dropzone, .dropzone {
  border-radius: 5px;
  border: dashed 1px #{$primary} !important;
  min-height: unset !important;

  .dz-message {
    width: 100%;
    text-align: center;
    font-weight: $font-weight-bold;
  }

  .dz-progress {
    display: none;
  }

  .dz-details {
    opacity: .8;
  }
}


.modal-inner-scroll {
  overflow: hidden !important;

  > .modal-dialog {
    max-height: 95vh;
    overflow: hidden;
  }

  .modal-content {
    max-height: 95vh;
  }

  .modal-body {
    height: auto;
    overflow: scroll;
  }
}

.traject-status-color {

  background-color: rgba(255, 255, 255, 0.15) !important;
  background-image: repeating-linear-gradient(-30deg, rgba(#F39100, .2), rgba(#F39100, .2) 1px, transparent 0, transparent 5px);

  &.load {
    background-color: rgba(243, 145, 0, 0.15) !important;
    background-image: repeating-linear-gradient(-30deg, rgba(#F39100, .2), rgba(#F39100, .2) 1px, transparent 0, transparent 5px);
  }

  &.unload {
    background-color: rgba(0, 128, 0, 0.16) !important;
    background-image: repeating-linear-gradient(-30deg, rgba(#008000, .2), rgba(#008000, .2) 1px, transparent 0, transparent 5px);
  }

  &.archived {
    background-color: rgba(128, 128, 128, 0.16) !important;
    background-image: repeating-linear-gradient(-30deg, rgba(#008000, .2), rgba(#008000, .2) 1px, transparent 0, transparent 5px);
  }

  &.scheduled {
    background-color: #e8e8e8 !important;
  }

}

.out-of-range > td {
  background-color: rgba(227, 0, 11, 0.1) !important;
  background-image: repeating-linear-gradient(-30deg, rgba(#E3000B, .1), rgba(#E3000B, .1) 1px, transparent 0, transparent 5px);
}

.shipment-legend {
  li {
    padding-bottom: 5px;
    margin-bottom: 5px;

    &:not(:last-child) {
      border-bottom: 1px solid $gray-200;
    }

    span {
      border: 1px solid $gray-500;
      padding: 3px 5px;
      text-decoration: underline;
      border-radius: 1px;
      text-align: center;
      width: 80px;
      display: inline-block;

      &.new {
        border-left: 4px $portal-status-neutral solid;
      }

      &.in-progress {
        border-left: 4px $portal-status-in-progress solid;
      }

      &.rejected {
        border-left: 4px $portal-status-error solid;
      }

      &.ready {
        border-left: 4px $portal-status-ready solid;
      }
    }
  }
}

.alert.alert-danger.alert-invert {
  color: white;
  background-color: $danger;
}

.cursor-pointer {
  cursor: pointer;
}

.status-table {

  tbody tr {
    opacity: .6;

    &.active, &:hover {
      opacity: 1;
    }
  }

  > tbody > tr > td:first-child {
    border-left: 0;
    padding-left: 10px;
    -moz-box-shadow: inset 5px 0 $portal-status-neutral;
    -webkit-box-shadow: inset 5px 0 $portal-status-neutral;
    box-shadow: inset 5px 0 $portal-status-neutral;
  }

  .status-in-progress > td:first-child {
    //border-left-color: $portal-status-in-progress;
    -moz-box-shadow: inset 5px 0 $portal-status-in-progress;
    -webkit-box-shadow: inset 5px 0 $portal-status-in-progress;
    box-shadow: inset 5px 0 $portal-status-in-progress;
  }

  .status-ready > td:first-child {
    //border-left-color: $portal-status-ready;
    -moz-box-shadow: inset 5px 0 $portal-status-ready;
    -webkit-box-shadow: inset 5px 0 $portal-status-ready;
    box-shadow: inset 5px 0 $portal-status-ready;
  }
}


/* Customize website's scrollbar
Not supports in Firefox and IE */

/* total width */
::-webkit-scrollbar {
  background-color: #f1f1f1;
  width: 8px;
}

/* total width */
::-webkit-scrollbar:horizontal {
  background-color: #f1f1f1;
  height: 8px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #a8a8a8;
  border-radius: 8px;
  border: 1px solid #f1f1f1;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}
